<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">TenWeeks</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Register Customer using Invite</p>
        </v-card-text>
        <v-card-text v-if="verifyLinkLoading">
          <p class="mb-2">
            <v-progress-circular :size="20" width="2" color="primary" indeterminate></v-progress-circular>
            Validating Invitation Link
          </p>
        </v-card-text>
        <v-card-text>
          <v-alert type="success" v-if="verifyLinkMessage"> {{ verifyLinkMessage }}</v-alert>
          <v-alert type="error" v-if="verifyErrorLinkMessage"> {{ verifyErrorLinkMessage }}</v-alert>
        </v-card-text>
        <v-card-text v-if="verifyLinkMessage">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-form ref="form" v-model="isValid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <label>Company Name</label>
                      <v-text-field
                        v-model="addCustomer.companyName"
                        type="text"
                        outlined
                        dense
                        placeholder="E.g. Star infotech Pvt. Ltd."
                        :rules="nameRules"
                      ></v-text-field>

                      <label>Representative Name</label>
                      <v-text-field
                        v-model="addCustomer.name"
                        type="text"
                        outlined
                        dense
                        placeholder="E.g. John Wick"
                        :rules="nameRules"
                      ></v-text-field>
                      <label>Representative Email</label>
                      <v-text-field
                        v-model="addCustomer.email"
                        type="email"
                        outlined
                        dense
                        placeholder="E.g. JohnWick@movie.com"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="text-center" cols="12">
                      <v-btn color="primary" v-on:click="registerCustomer" :loading="loading" :disabled="loading">
                        Register as Customer
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <router-link to="/">
                Go to Login
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiAlertCircleOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import Axios from 'axios'

export default {
  setup() {
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAlertCircleOutline,
        mdiCheckboxMarkedCircleOutline,
      },
      code: 0,
      loading: false,
      verifyLinkLoading: false,
      verifyLinkMessage: '',
      verifyErrorLinkMessage: '',
      addCustomer: { name: '', email: '', code: '', companyName: '' },
      isValid: false,
      nameRules: [
        v => !!v || 'Name is required.',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters.',
      ],
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'Please enter valid E-mail.'],
    }
  },
  created() {
    let e = this
    e.code = parseInt(e.$route.params.i)
    e.verifyLink()
  },
  methods: {
    verifyLink() {
      let e = this
      if (!e.code) {
        e.errorMessage('The verification code might have expired or is invalid.')
        e.verifyErrorLinkMessage = 'The verification code might have expired or is invalid.'
        return false
      }
      e.verifyLinkLoading = true
      Axios.post('/customer/invite/verify', {
        code: e.code,
      })
        .then(function(response) {
          e.verifyLinkMessage = 'Link verified successfully.'
          e.verifyErrorLinkMessage = ''
        })
        .catch(function(response) {
          e.verifyErrorLinkMessage =
            response.response.status == 500
              ? 'Something went wrong! Please try again later.'
              : response.response.data.errors[0].title

          e.verifyLinkMessage = ''
        })
        .finally(function() {
          e.verifyLinkLoading = false
        })
    },
    registerCustomer() {
      let e = this
      let isValidated = e.$refs.form.validate()
      if (isValidated) {
        e.loading = true
        e.addCustomer.code = e.code

        Axios.post('/customer/register', e.addCustomer)
          .then(function(response) {
            if (response.data.succeeded) {
              e.successMessage(response.data.message)
              e.$router.push({ name: 'payments' })
            }
          })
          .catch(function(response) {})
          .finally(function() {
            e.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
